import React, { useState } from 'react';
import UnderArmed from '../../assets/svg/under-arm.svg';
import BilihCheretaLogo from '../../assets/logos/bilih_chereta_logo_long.svg';



function Header() {
    const [isHovered, setIsHovered] = useState(false);

    const handleButtonHover = () => {
        setIsHovered(true);
    };

    const handleButtonLeave = () => {
        setIsHovered(false);
    };

    const buttonStyles = {
    };

    const imageStyles = {
        display: isHovered ? 'block' : 'none',
    };

    return (
        <>
            <div className="w-full sticky top-0  bg-white/70 z-40 border-b-[1px] border-b-[#012D4C] border-opacity-5 ">
                <div class="w-full flex justify-center text-white bg-gradient-to-r from-[#faaa40] via-[#FBA22D] to-[#faaa40] font-bold text-lg py-2">
                    <span class="tracking-wide text-[1.1rem] font-medium"><a>Try Now! Free for 30 Days.</a></span>
                </div>
                <div className='bg-white'>
                    <div className='w-full max-w-full '>
                        <div className='flex flex-row justify-items-center'>
                            <div className='items-center bg-white box-border flex flex-shrink-0 h-[75px] '>
                                <img className='flex-shrink-0 h-[40px] md:ml-[118px] md:m-[15rem] ml-[30px] m-[0px] md:h-[40px]' src={BilihCheretaLogo} />
                            </div>
                            <div className='relative flex items-center hidden lg:flex'>
                                <button className='relative  flex  '>
                                    <p className='text-[#012d4c] text-[1.1rem] font-medium tracking-tight' >Tender</p>
                                    <img className='absolute inset-x-0 bottom-0 justify-end' />
                                </button>
                                <button className='relative flex'>
                                    <p className='text-[#a8a9a9] text-[1.1rem] font-medium tracking-tight ml-[30px]' style={buttonStyles}
                                        onMouseEnter={handleButtonHover}
                                        onMouseLeave={handleButtonLeave}>FAQ</p>
                                    <img className='absolute inset-x-5 bottom-0  justify-end' src={UnderArmed} style={imageStyles} />
                                </button>
                                <button>
                                    <p className='text-[#a8a9a9] text-[1.1rem] font-medium tracking-tight ml-[30px]'>Pricing</p>
                                </button>

                                {/* <div>
                                    <button style={buttonStyles}
                                        onMouseEnter={handleButtonHover}
                                        onMouseLeave={handleButtonLeave}>
                                        Hover me
                                        <img className='absolute   w-full h-full' src={UnderArmed} style={imageStyles} />

                                    </button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </>
    )
}

export default Header;

{/* <nav id="navContainer" class="border-gray-200 text-[#664bc7] md:text-sm lg:text-xl px-2 md:px-0 py-2 ">
                <div class="flex flex-wrap flex-row-reverse items-center justify-start py-2 md:mx-[5%] xl:mx-[7%] relative">
                    <a href="/" class="absolute left-0 top-2">
                        <img class="w-24 sm:w-32 lg:w-40" src="/assets/images/logowitht.svg" alt="" />
                    </a>
                    <div class="flex items-center">

                        <Button id="burggerMenu" data-collapse-toggle="mobile-menu" type="button" class="md:hidden ml-3 text-gray-400 hover:text-gray-900 focus:outline-none  rounded-lg inline-flex items-center justify-center" aria-controls="mobile-menu-2" aria-expanded="false">
                            <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path>
                            </svg>
                            <svg class="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                            </svg>
                        </Button>
                        <Button></Button>
                    </div>
                    <div class="hidden md:block w-full md:w-auto" id="mobile-menu">
                        <ul class="flex-col md:flex-row flex md:space-x-6 mt-4 md:mt-0 font-light text-[#664bc7] divide-y divide-slate-100 md:divide-transparent">
                            <li class="flex items-center">
                                <div onclick="searchArea()" class="flex  flex-col cursor-pointer hidden md:block lg:hidden items-center  rounded-t-lg ">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-5 ">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"></path>
                                    </svg>
                                </div>
                            </li>
                            <li class="flex items-center">
                                <a href="https://ichereta.com/tenders" class="hover:bg-gray-50  flex items-center md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0" aria-current="page">Tenders </a>
                            </li>
                            <li class="flex items-center">
                                <a href="https://ichereta.com/pricing" class="hover:bg-gray-50 border-b flex items-center  border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0">Pricing </a>
                            </li>
                            <li class="flex items-center">
                                <a href="https://ichereta.com/FAQ" class="hover:bg-gray-50 border-b flex items-center  border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0">FAQ </a>
                            </li>
                            <li class="flex items-center">
                                <a href="https://ichereta.com/register" class="hover:bg-gray-50 border-b flex items-center  border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0">Sign Up </a>
                            </li>
                            <li class="flex items-center">
                                <a href="https://ichereta.com/login" class="border-b border-gray-100 flex items-center bg-[#664bc7] bg-opacity-30 font-bold rounded md:border-0 block pl-3 px-4 py-2 ">Login </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav> */}