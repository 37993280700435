import React from 'react'
import RightIcon from '../../../assets/svg/right_icon.svg';
import PopularIcon from '../../../assets/svg/populate.svg';


function Pricing() {
    return (
        <div class="w-full xl:px-[5%] 2xl:px-[15%] flex justify-center my-4 sm:my-12 flex flex-col">
            <div class="w-full flex flex-col text-center my-24 space-y-1 sm:space-y-3">
                <p class="text-6xl">Bilih Chereta <span className='text-[#FBA22D]'>Pricing.</span> </p>
                <p class="text-xl text-center text-gray-800 md:text-xl">Your access to leading site for online Tender is only moments away. Every day new<br /> businesses available here on GoTender.<br /></p>
            </div>
            <div class="flex flex-col lg:flex-row items-center lg:justify-evenly space-y-5 lg:space-y-0">

                <div class="flex flex-col w-[320px] border border-[#012D4C] border-opacity-30 text-center space-y-3 rounded-md shadow-xl bg-slate-100">
                    <div class="h-35 flex w-full flex-col rounded-t-md px-10 py-5 items-center justify-center bg-white  border-[#012D4C] border-opacity-30 border-b-[1px]">
                        <p class="text-4xl text-gray-800">3 months</p>
                        <div class="inline-flex  items-center justify-end space-x-3">
                            <p class="text-4xl text-gray-800">700</p>
                            <p class="text-xl text-gray-800">Birr</p>
                        </div>
                    </div>
                    <div className='flex flex-col items-center justify-center px-10 py-5'>
                        <div class="space-y-3 text-sm ">
                            <div class="flex items-center">
                                <img class="w-4 mr-2" src={RightIcon} alt="" />
                                <span> Unlimited Tender Access</span>
                            </div>
                            <div class="flex items-center">
                                <img class="w-4 mr-2" src={RightIcon} alt="" />
                                <span> Daily Email Alert</span>
                            </div>
                            <div class="flex items-center">
                                <img class="w-4 mr-2" src={RightIcon} alt="" />
                                <span>Daily SMS Alert</span>
                            </div>
                            <div class="flex items-center">
                                <img class="w-4 mr-2" src={RightIcon} alt="" />
                                <span> Daily Telegram Notification</span>
                            </div>
                            <div class="flex items-center">
                                <img class="w-4 mr-2" src={RightIcon} alt="" />
                                <span> Save Tenders</span>
                            </div>
                            <div class="flex items-center">
                                <img class="w-4 mr-2" src={RightIcon} alt="" />
                                <span> 4 Languages</span>
                            </div>
                            <div class="flex items-center">
                                <img class="w-4 mr-2" src={RightIcon} alt="" />
                                <span> From All Over Ethiopia</span>
                            </div>
                            <div class="flex items-center">
                                <img class="w-4 mr-2" src={RightIcon} alt="" />
                                <span> Support Service 24/7</span>
                            </div>
                        </div>

                        <a class="rounded border text-center border-[#FBA22D] border-opacity-60 text-[#FBA22D] py-1 my-4 w-full">
                            <p class="text-2xl text-[#FBA22D]">Get started</p>
                        </a>
                    </div>
                </div>

                <div class="flex flex-col w-[320px] lg:w-[370px] pb-16 border-[3px] border-[#012D4C] text-center space-y-3 rounded-md shadow-xl">
                    <div class="bg-[#012D4C] text-white font-semibold py-4 rounded-t-[3px] w-full text-center flex-row space-x-2 flex justify-center border-[#012D4C] border-[1px]">
                        <p class="text-2xl text-white">Most popular</p>
                        <img class='w-4' src={PopularIcon} />


                    </div>
                    <div class="flex flex-col px-10 space-y-7">
                        <div class="h-35 flex w-full flex-col rounded-t-md space-y-3 px-10 py-5 items-center justify-center bg-white  border-[#012D4C] border-opacity-30 border-b-[1px]">
                            <p class="text-5xl text-gray-800">1 year </p>
                            <div class="inline-flex  items-center justify-end space-x-3 ">
                                <p class="text-5xl text-gray-800">1800</p>
                                <p class="text-xl text-gray-800">Birr</p>

                            </div>
                        </div>
                        <div class="space-y-3 text-sm">
                            <div class="flex items-center">
                                <img class="w-4 mr-2" src={RightIcon} alt="" />
                                <span> Unlimited Tender Access</span>
                            </div>
                            <div class="flex items-center">
                                <img class="w-4 mr-2" src={RightIcon} alt="" />
                                <span> Daily Email Alert</span>
                            </div>
                            <div class="flex items-center">
                                <img class="w-4 mr-2" src={RightIcon} alt="" />
                                <span>Daily SMS Alert</span>
                            </div>
                            <div class="flex items-center">
                                <img class="w-4 mr-2" src={RightIcon} alt="" />
                                <span> Daily Telegram Notification</span>
                            </div>
                            <div class="flex items-center">
                                <img class="w-4 mr-2" src={RightIcon} alt="" />
                                <span> Save Tenders</span>
                            </div>
                            <div class="flex items-center">
                                <img class="w-4 mr-2" src={RightIcon} alt="" />
                                <span> 4 Languages</span>
                            </div>
                            <div class="flex items-center">
                                <img class="w-4 mr-2" src={RightIcon} alt="" />
                                <span> From All Over Ethiopia</span>
                            </div>
                            <div class="flex items-center">
                                <img class="w-4 mr-2" src={RightIcon} alt="" />
                                <span> Support Service 24/7</span>
                            </div>
                        </div>
                        <a rounded="" class=" rounded-md  text-white py-1.5">
                            <div class=" h-12 hover:bg-[#fba22dcb] bg-[#FBA22D] border rounded-lg border-gray-400 border-opacity-30 justify-center items-center flex" >
                                <p class="text-2xl text-white">Get started</p>
                            </div>
                        </a>
                    </div>
                </div>

                <div class="flex flex-col w-[320px]  border border-[#012D4C] border-opacity-30 text-center space-y-3 rounded-md shadow-xl bg-slate-100">
                    <div class="h-35 flex w-full flex-col  rounded-t-md px-10 py-5 items-center justify-center bg-white  border-[#012D4C] border-opacity-30 border-b-[1px]">
                        <p class="text-4xl text-gray-800">6 months</p>
                        <div class="inline-flex  items-center justify-end space-x-2">
                            <p class="text-4xl text-gray-800">1000</p>
                            <p class="text-xl text-gray-800">Birr</p>
                        </div>
                    </div>
                    <div className='flex flex-col items-center justify-center px-10 py-5'>
                        <div class="space-y-3 text-sm ">
                            <div class="flex items-center">
                                <img class="w-4 mr-2" src={RightIcon} alt="" />
                                <span> Unlimited Tender Access</span>
                            </div>
                            <div class="flex items-center">
                                <img class="w-4 mr-2" src={RightIcon} alt="" />
                                <span> Daily Email Alert</span>
                            </div>
                            <div class="flex items-center">
                                <img class="w-4 mr-2" src={RightIcon} alt="" />
                                <span>Daily SMS Alert</span>
                            </div>
                            <div class="flex items-center">
                                <img class="w-4 mr-2" src={RightIcon} alt="" />
                                <span> Daily Telegram Notification</span>
                            </div>
                            <div class="flex items-center">
                                <img class="w-4 mr-2" src={RightIcon} alt="" />
                                <span> Save Tenders</span>
                            </div>
                            <div class="flex items-center">
                                <img class="w-4 mr-2" src={RightIcon} alt="" />
                                <span> 4 Languages</span>
                            </div>
                            <div class="flex items-center">
                                <img class="w-4 mr-2" src={RightIcon} alt="" />
                                <span> From All Over Ethiopia</span>
                            </div>
                            <div class="flex items-center">
                                <img class="w-4 mr-2" src={RightIcon} alt="" />
                                <span> Support Service 24/7</span>
                            </div>
                        </div>

                        <a class="rounded border text-center border-[#FBA22D] border-opacity-60 text-[#FBA22D] py-1 my-4 w-full">
                            <p class="text-2xl text-[#FBA22D]">Get started</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pricing