import React from 'react';
import BilihCheretaLogoOnly from '../../../assets/logos/bilih_logo_only.svg';
import BilihCheretaLogoText from '../../../assets/logos/bilih_chereta_text_only.svg';

function Hero() {
    return (
        <div>
            {/* <div class="h-80 flex items-center my-8 md:my-40 relative">
                <img class="hidden lg:inline absolute lg:left-24 xl:left-32 lg:w-80 2xl:w-96" src="/assets/images/girl.png" alt="" />
                <div class="w-full h-60 bg-[#664bc7]  md:px-[15%] xl:px-[17%]">
                    <div class=" h-full flex justify-center lg:justify-end items-center">
                        <div class="flex flex-col text-white items-center space-y-3 sm:space-y-4">
                            <span class="text-[7vw] sm:text-5xl font-bold">ሁሉንም ጨረታ በአንድ ቦታ!</span>
                            <span class="text-[4vw] sm:text-2xl font-semibold">ከመላው ኢትዮጵያ በአንድ ቦታ።</span>
                            <a href="https://ichereta.com/register" class="bg-[#ebba5c] py-3  w-52 rounded font-semibold text-center">Subscribe Now</a>
                        </div>
                    </div>
                </div>
            </div> */}
            <div class=" bg-[#ECEFF1] h-[230] w-full" >
                <div class=" sm:space-x-5 lg:space-x-24 space-y-10   justify-center items-center  flex flex-col md:flex-row py-5" >
                    <img class="hidden lg:inline w-96 h-16 rounded-lg" src={BilihCheretaLogoText} />
                    <img class=" h-full" src={BilihCheretaLogoOnly} />
                    <p class="text-5xl text-center font-semibold">All tenders in one<br /> <span className='text-[#FBA22D]'>Place.</span></p>
                </div>
            </div>
        </div>
    )
}

export default Hero