import React, { useState } from 'react'
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import RightBadge from '../../assets/svg/right_badge.svg';
import Bell from '../../assets/svg/bell.svg';
import Message from '../../assets/svg/message.svg';
import HeroImage from '../../assets/images/bilih_image.png';
import BilihCheretaLogo from '../../assets/logos/bilih_chereta_logo_long.svg';
import Arrow from '../../assets/svg/arrow.svg';
import AccordionItems from './widgets/AccordionItems';
import Pricing from './widgets/Pricing';
import Hero from './widgets/Hero';
import Tender from './widgets/Tender';
import FloatingButton from './widgets/Floatingbutton';




function Home() {

    const [active, setActive] = useState(null);

    const handleToggle = (index) => {
        if (active === index) {
            setActive(null);
        } else {
            setActive(index);
        }
    }


    return (
        <div>
            <Header />
            {/* <div className='w-screen h-screen bg-blue-300'>
            </div> */}
            <div class="w-full md:pl-[5%] xl:pl-[7%] grid grid-cols-1 md:grid-cols-2 bg-[#f9f8fd] pb-40">
                <div class="text-[#012D4C] my-12 lg:my-16 px-5 md:px-0">
                    <span class="text-4xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-6xl font-normal">Improve your business
                        <br />with &nbsp;
                        <span class="text-4xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-6xl text-[#FBA22D] font-semibold">bilih chereta.</span></span>

                    <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-1 my-12 md:my-6 lg:my-12">
                        <div class="flex items-center">
                            <img class="w-4 mr-2" src={RightBadge} alt="" />
                            <span>Accessible with Differents Language Updates</span>
                        </div>

                        <div class="flex items-center">
                            <img class="w-4 mr-2" src={RightBadge} alt="" /><span>Daily Alerts</span>
                        </div>
                        <div class="flex items-center">
                            <img class="w-4 mr-2" src={RightBadge} alt="" /><span>Unlimited Tender Access</span>
                        </div>
                    </div>
                    <div className='flex grid flex-col grid-cols-1 space-x-4 space-y-4 lg:grid-cols-2 md:space-y-3 sm:space-y-10 md:flex-col sm:flex-col'>
                        <div class="w-48 h-12">
                            <div class="flex items-center justify-center  py-1.5 px-5 bg-[#FBA22D] font-normal rounded">
                                <p class="text-2xl font-medium text-white md:">Get Started</p>
                            </div>
                        </div>

                        <div className='mt-9'>
                            <div class="flex  items-center justify-center">
                                <div class="relative " style={{ width: "265.75px", height: "95px" }}>
                                    <div class=" flex h-20  w-64 items-center  rounded-xl bg-[#A8A9A9] bg-opacity-20">
                                        <div class="flex flex-row h-14 items-center align-middle">
                                            <div class="absolute left-2 top-6 w-9 h-8 rounded-lg ml-2" >
                                                <img src={Message} />
                                            </div>
                                            <div class="ml-16  w-48 flex-col space-y-0">
                                                <p class="text-xl text-gray-800">Alert</p>
                                                <p class="text-xs text-gray-800 text-opacity-70">Our notification will keep<br />you stand out.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="absolute bottom-0 right-0 h-8 w-6 rounded-lg">
                                        <img src={Bell} />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col  w-full items-center justify-center px-3">
                    <img src={HeroImage} className='sm:w-[400px] md:w-[500px] lg:w-[600px] 2xl:[00px] ' />
                </div>
            </div>

            <div className='flex flex-col items-center justify-center mx-5 -mt-24'>
                <div class="flex items-center justify-around  rounded-lg bg-gray-100 h-[161px] px-[4%] py-[3%] gap-0 md:gap-4 sm:gap-4 sm:flex-row flex-col" >
                    <div class="relative  flex flex-col justify-center items-center" >

                        <p class=" text-2xl font-semibold text-gray-800 sm:text-4xl">
                            Grow your business<br />
                            {/* <span className='hidden md:block'>with</span> */}
                        </p>
                        <img class="h-[50px] w-60 rounded-lg md:bottom-0 md:right-0 md:h-10" src={BilihCheretaLogo} />
                    </div>

                    <div class="w-0.5 h-28 bg-gray-300 hidden md:block " />
                    <div class="flex items-center flex-col font-semibold ">
                        <p class="text-xl text-gray-800 sm:text-3xl">Contact us</p>
                        <p class="text-xl text-gray-800 sm:text-3xl">9570</p>
                    </div>
                </div>

            </div>
            {/* <div class="flex flex-col px-[4%] md:px-[7%] xl:px-[10%] h-60 -mb-24 relative">
                <div class="bg-[#f5f4f9] h-full rounded-md relative flex flex-col md:flex-row items-center justify-center md:justify-between md:px-8 lg:px-24">
                    <div class="hidden md:inline w-0.5 h-[90%] bg-gray-200 absolute left-[50%]"></div>
                    <span class="text-2xl sm:text-3xl md:text-3xl xl:text-4xl 2xl:text-5xl font-bold text-[#29204e] text-center md:text-left">Grow Your Business <br />With <span class="text-[#6449c3]">iChereta.</span></span>
                    <div class="flex flex-col items-center space-y-3">
                        <a href="https://ichereta.com/register" class=" text-center bg-[#ebba5c] py-3 w-52 rounded font-semibold text-white my-4">Sign Up Now</a>
                        <div class="flex flex-col items-center text-[#29204e] font-bold ">
                            <span>Got Questions?</span>
                            <span>Call Us 0955305555</span>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <div class=" bg-[#ECEFF1] h-[230] w-full" >
                <div class=" sm:space-x-28 space-x-10 space-y-5 items-start justify-center items-center  flex flex-col md:flex-row py-5" >
                    <img class="hidden lg:inline w-96 h-16 rounded-lg" src={BilihCheretaLogoText} />
                    <img class=" w-40 md:w-52 h-full rounded-lg" src={BilihCheretaLogoOnly} />
                    <p class="text-5xl text-center font-semibold">All tenders in one<br /> <span className='text-[#FBA22D]'>Place.</span></p>
                </div>
            </div> */}

            <Tender />

            <Pricing />
            <Hero />
            <AccordionItems />
            <FloatingButton />

            <Footer />
        </div>
    )
}

export default Home;