import React from 'react';
import { ImWhatsapp } from "react-icons/im";
import { FaTelegram } from "react-icons/fa";
import { Link } from 'react-router-dom';



const FloatingButton = () => {
    return (
        <div className='fixed flex flex-col gap-2 bottom-10 right-10 md:flex-row sm:flex-row'>
            <Link to="https://wa.me/251924473333" target='_blank'>

                <button className=" p-3 text-white bg-[#25D366] rounded-full shadow-lg">
                    <ImWhatsapp className='w-8 h-8' />
                </button>
            </Link>
            <Link to="https://t.me/bilihchereta" target='_blank'>
                <button className=" p-3 text-white bg-[#34B7F1] rounded-full shadow-lg" >
                    <FaTelegram className='w-8 h-8' />
                </button>
            </Link>
        </div>

    );
};

export default FloatingButton;
