import React from 'react';
import OpenBadge from '../../../assets/svg/Open_badge.svg';
import ClosedBadge from '../../../assets/svg/Closed_badge.svg';
import Tag from '../../../assets/svg/Tag.svg';
import RightArrow from '../../../assets/svg/Arrow_Right.svg';
import SaveIcon from '../../../assets/svg/Save_Icon.svg';
import SavedIcon from '../../../assets/svg/Saved_Icon.svg';
import { Button } from 'antd';

import './Tender.css';






function Tender() {
    const handleClick = () => {
        console.log('Button clicked');
    };

    return (
        <div>
            <div className='w-full  -mt-24 bg-[#ECEFF1] py-20 '>
                <div className='flex-1 max-w-full p-8 mx-auto sm:p-16'>
                    <div className='grid justify-center grid-flow-row-dense grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:gird-cols-5 2xl:grid-cols-6 grid-auto-rows-auto'>
                        <div className="px-5 lg:col-span-1 lg:row-span- sm:grid-cols-1">
                            <div className='py-12 bg-white rounded-md'>
                                <div className='flex flex-row items-center justify-center w-full'>
                                    <p class="text-2xl text-gray-800">Tender Search</p>
                                </div>
                                <div className='px-4 '>
                                    <label for="username">

                                        <p class="text-sm text-gray-800">Title</p>

                                    </label>
                                    <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="title" type="text" placeholder="type tender title or company name" />
                                </div>
                                <div className='flex flex-col items-center justify-center w-full gap-4 mt-[36px]'>
                                    <div class="w-32 h-8">
                                        <div class="flex items-center justify-center flex-1 h-full pl-10 pr-9 pt-2 pb-1.5 bg-[#FBA22D] bg-opacity-90 rounded">
                                            <p class="text-sm font-medium tracking-widest text-white">Search</p>
                                        </div>
                                    </div>
                                    <div class="w-32 h-8">
                                        <div class="flex items-center justify-center flex-1 h-full px-11 pt-2 pb-1.5  bg-opacity-50 border rounded-lg border-gray-400 border-opacity-30">
                                            <p class="text-sm font-medium tracking-widest text-gray-800">Reset</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className=" lg:col-start-2 lg:col-span-3 xl:col-span-4">
                            <div className='rounded-lg '>
                                <div className="flex inline-flex flex-row items-center justify-between w-full px-3" >
                                    <p className="text-2xl text-gray-800">All Tenders</p>
                                    <p className="text-sm text-gray-800 underline">See all</p>
                                </div>
                                <div className='flex items-center justify-center w-full'>
                                    <div class="   bg-opacity-70  relative overflow-hidden">
                                        <div id="leftFade" class="bg-gradient-to-r from-[#f0f2f5] inline to-transparent h-full w-10 absolute left-0"></div>
                                        <div id="rightFade" class="bg-gradient-to-l from-[#f0f2f5] inline to-transparent h-full w-10 absolute right-0"></div>
                                        <div class=" flex text-gray-600 items-center overflow-x-auto space-x-2 scroll px-3">


                                            <div class="border cursor-pointer bg-white text-gray-600  pl-4 pr-5 min-w-max rounded font-medium py-1 flex items-center hover:border-[#FBA22D] hover:text-[#FBA22D]">
                                                <div class="flex justify-center items-center space-x-3">
                                                    <img src={Tag} />
                                                    <div>
                                                        <span>Construction and Water Works</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="border  cursor-pointer bg-white text-gray-600  pl-4 pr-5 min-w-max rounded font-medium py-1 flex items-center hover:border-[#FBA22D] hover:text-[#FBA22D]">
                                                <div class="flex justify-center items-center space-x-3">
                                                    <img src={Tag} />
                                                    <div>
                                                        <span>Construction and Water Works</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="border cursor-pointer  bg-white text-gray-600  pl-4 pr-5 min-w-max rounded font-medium py-1 flex items-center hover:border-[#FBA22D] hover:text-[#FBA22D]">
                                                <div class="flex justify-center items-center space-x-3">
                                                    <img src={Tag} />
                                                    <div>
                                                        <span>Sales, Disposals and Foreclosure</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="border  cursor-pointer bg-white text-gray-600  pl-4 pr-5 min-w-max rounded font-medium py-1 flex items-center hover:border-[#FBA22D] hover:text-[#FBA22D]">
                                                <div class="flex justify-center items-center space-x-3">
                                                    <img src={Tag} />
                                                    <div>
                                                        <span>Sales, Disposals and Foreclosure</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="border  cursor-pointer bg-white text-gray-600  pl-4 pr-5 min-w-max rounded font-medium py-1 flex items-center hover:border-[#FBA22D] hover:text-[#FBA22D]">
                                                <div class="flex justify-center items-center space-x-3">
                                                    <img src={Tag} />
                                                    <div>
                                                        <span>Sales, Disposals and Foreclosure</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className=''>
                                    <div className='bg-white mt-[22px] flex flex-col rounded-lg py-[22px] px-[31px] '>
                                        <p className="text-base text-gray-800" >የአማራ ህንፃ ሥራዎች ኮንስትራክሽን ድርጅት እየገነባቸው ላሉ ፕሮጀክቶች አገልግሎት የሚውሉ የተለያዩ የቤዝመንት ውሃ ስርገት መከላከያ አቅርቦ የመግጠም ስራ ፣ ፐርፎሬትድ ፓይፕ የማቅረብ ስራ ፣ ጂኦቴክስታይል የማቅረብ ስራ ፣<br /></p>
                                        <div className="flex flex-col items-center w-full px-[31px] opacity-50 bg-opacity-80 my-[11px]"  >
                                            <div className='bg-gray-300 h-[2px] w-full' ></div>
                                        </div>
                                        <div className="inline-flex flex-col space-y-0.5 items-start justify-start w-80 h-16">
                                            <div className="inline-flex space-x-0.5 items-center justify-start w-72 h-5">
                                                <p className="text-base text-gray-800">Bid Opening Date: </p>
                                                <p className="text-sm text-gray-800">December 25, 2022</p>
                                            </div>
                                            <div className="inline-flex items-center justify-start w-5/6 h-5 space-x-1">
                                                <p className="text-base text-gray-800">Bid Closing Date: </p>
                                                <p className="text-sm text-gray-800">January 25, 2023</p>
                                            </div>
                                            <div className="inline-flex items-center justify-start w-full h-5 space-x-1">
                                                <p className="text-base text-gray-800">Published On:</p>
                                                <p className="text-sm text-gray-800">Addis Zemen - (Apr 25, 2023)</p>
                                            </div>
                                        </div>
                                        <div className='inline-flex gap-2 my-[11px]'>
                                            <div className="inline-flex space-x-2.5 items-center justify-start  h-5  ">
                                                <img src={OpenBadge} />
                                                <p className="text-base font-semibold text-green-500 " >Bid Open</p>
                                            </div>
                                            <div className="inline-flex space-x-2.5 items-center justify-start h-5">
                                                <img src={ClosedBadge} />
                                                <p className="text-base font-semibold text-red-500">Bid Closed</p>
                                            </div>

                                        </div>


                                        <div className="flex flex-col items-center w-full px-[31px] opacity-50 bg-opacity-80 my-[11px]"  >
                                            <div className='bg-gray-300 h-[2px] w-full' ></div>
                                        </div>

                                        <div className='flex items-center justify-center w-full'>
                                            <div class="   bg-opacity-50  relative overflow-hidden">
                                                <div id="leftFade" class="bg-gradient-to-r from-[#ffffff] inline to-transparent h-full w-5 absolute left-0"></div>
                                                <div id="rightFade" class="bg-gradient-to-l from-[#ffffff] inline to-transparent h-full w-5 absolute right-0"></div>
                                                <div class=" flex text-gray-600 items-center overflow-x-auto space-x-2 scroll px-3 md:flex-row flex-row  md:space-y-2 sm:space-y-2">


                                                    <div class="border cursor-pointer bg-white text-gray-600  pl-4 pr-5 min-w-max rounded font-medium py-1 flex items-center hover:border-[#FBA22D] hover:text-[#FBA22D]">
                                                        <div class="flex justify-center items-center space-x-3">
                                                            <img src={Tag} />
                                                            <div>
                                                                <span>Construction and Water Works</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="border  cursor-pointer bg-white text-gray-600  pl-4 pr-5 min-w-max rounded font-medium py-1 flex items-center hover:border-[#FBA22D] hover:text-[#FBA22D]">
                                                        <div class="flex justify-center items-center space-x-3">
                                                            <img src={Tag} />
                                                            <div>
                                                                <span>Construction and Water Works</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="border cursor-pointer  bg-white text-gray-600  pl-4 pr-5 min-w-max rounded font-medium py-1 flex items-center hover:border-[#FBA22D] hover:text-[#FBA22D]">
                                                        <div class="flex justify-center items-center space-x-3">
                                                            <img src={Tag} />
                                                            <div>
                                                                <span>Sales, Disposals and Foreclosure</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="border  cursor-pointer bg-white text-gray-600  pl-4 pr-5 min-w-max rounded font-medium py-1 flex items-center hover:border-[#FBA22D] hover:text-[#FBA22D]">
                                                        <div class="flex justify-center items-center space-x-3">
                                                            <img src={Tag} />
                                                            <div>
                                                                <span>Sales, Disposals and Foreclosure</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="border  cursor-pointer bg-white text-gray-600  pl-4 pr-5 min-w-max rounded font-medium py-1 flex items-center hover:border-[#FBA22D] hover:text-[#FBA22D]">
                                                        <div class="flex justify-center items-center space-x-3">
                                                            <img src={Tag} />
                                                            <div>
                                                                <span>Sales, Disposals and Foreclosure</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div className='flex flex-row gap-4'>
                                            <div class="bg-[#FBA22D] hover:bg-[#fba22dcb] px-2 py-2 rounded-md inline-flex flex-row  items-center justify-center gap-[5px] my-3 cursor-pointer" onClick={handleClick}>
                                                <p class=" text-sm font-medium tracking-widest text-white" >Read More</p>
                                                <img src={RightArrow} />
                                            </div>

                                            <div class=" border cursor-pointer hover:border-[#FBA22D] border-[#012D4C] border-opacity-80  px-4 py-2 rounded-md inline-flex flex-row  items-center justify-center gap-[11px] my-3">
                                                <img src={SaveIcon} />

                                                <p class=" text-sm font-medium tracking-widest text-[#012D4C]">Save</p>
                                            </div>
                                        </div>


                                    </div>
                                    <div className='bg-white mt-[22px] flex flex-col rounded-lg py-[22px] px-[31px] '>
                                        <p className="text-base text-gray-800" >የአማራ ህንፃ ሥራዎች ኮንስትራክሽን ድርጅት እየገነባቸው ላሉ ፕሮጀክቶች አገልግሎት የሚውሉ የተለያዩ የቤዝመንት ውሃ ስርገት መከላከያ አቅርቦ የመግጠም ስራ ፣ ፐርፎሬትድ ፓይፕ የማቅረብ ስራ ፣ ጂኦቴክስታይል የማቅረብ ስራ ፣<br /></p>
                                        <div className="flex flex-col items-center w-full px-[31px] opacity-50 bg-opacity-80 my-[11px]"  >
                                            <div className='bg-gray-300 h-[2px] w-full' ></div>
                                        </div>
                                        <div className="flex flex-col ">

                                            <span className='text-base font-semibold text-gray-800'>Bid Opening Date:<span class="text-sm text-gray-800 font-normal">December 25, 2022</span>
                                            </span>
                                            <span className='text-base font-semibold text-gray-800'>Bid Closing Date:<span class="text-sm text-gray-800 font-normal">January 25, 2023</span>
                                            </span>
                                            <span className='text-base font-semibold text-gray-800'>Published On:<span class="text-sm text-gray-800 font-normal">Addis Zemen - (Apr 25, 2023)</span>
                                            </span>

                                        </div>
                                        <div className='inline-flex gap-2 my-[11px]'>
                                            <div className="inline-flex space-x-2.5 items-center justify-start  h-5  ">
                                                <img src={OpenBadge} />
                                                <p className="text-base font-semibold text-green-500 " >Bid Open</p>
                                            </div>
                                            <div className="inline-flex space-x-2.5 items-center justify-start h-5">
                                                <img src={ClosedBadge} />
                                                <p className="text-base font-semibold text-red-500">Bid Closed</p>
                                            </div>

                                        </div>


                                        <div className="flex flex-col items-center w-full px-[31px] opacity-50 bg-opacity-80 my-[11px]"  >
                                            <div className='bg-gray-300 h-[2px] w-full' ></div>
                                        </div>

                                        <div className='flex items-center justify-center w-full'>
                                            <div class="   bg-opacity-50  relative overflow-hidden">
                                                <div id="leftFade" class="bg-gradient-to-r from-[#ffffff] inline to-transparent h-full w-5 absolute left-0"></div>
                                                <div id="rightFade" class="bg-gradient-to-l from-[#ffffff] inline to-transparent h-full w-5 absolute right-0"></div>
                                                <div class=" flex text-gray-600 items-center overflow-x-auto space-x-2 scroll px-3 md:flex-row flex-row  md:space-y-2 sm:space-y-2">


                                                    <div class="border cursor-pointer bg-white text-gray-600  pl-4 pr-5 min-w-max rounded font-medium py-1 flex items-center hover:border-[#FBA22D] hover:text-[#FBA22D]">
                                                        <div class="flex justify-center items-center space-x-3">
                                                            <img src={Tag} />
                                                            <div>
                                                                <span>Construction and Water Works</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="border  cursor-pointer bg-white text-gray-600  pl-4 pr-5 min-w-max rounded font-medium py-1 flex items-center hover:border-[#FBA22D] hover:text-[#FBA22D]">
                                                        <div class="flex justify-center items-center space-x-3">
                                                            <img src={Tag} />
                                                            <div>
                                                                <span>Construction and Water Works</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="border cursor-pointer  bg-white text-gray-600  pl-4 pr-5 min-w-max rounded font-medium py-1 flex items-center hover:border-[#FBA22D] hover:text-[#FBA22D]">
                                                        <div class="flex justify-center items-center space-x-3">
                                                            <img src={Tag} />
                                                            <div>
                                                                <span>Sales, Disposals and Foreclosure</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="border  cursor-pointer bg-white text-gray-600  pl-4 pr-5 min-w-max rounded font-medium py-1 flex items-center hover:border-[#FBA22D] hover:text-[#FBA22D]">
                                                        <div class="flex justify-center items-center space-x-3">
                                                            <img src={Tag} />
                                                            <div>
                                                                <span>Sales, Disposals and Foreclosure</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="border  cursor-pointer bg-white text-gray-600  pl-4 pr-5 min-w-max rounded font-medium py-1 flex items-center hover:border-[#FBA22D] hover:text-[#FBA22D]">
                                                        <div class="flex justify-center items-center space-x-3">
                                                            <img src={Tag} />
                                                            <div>
                                                                <span>Sales, Disposals and Foreclosure</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div className='flex flex-row gap-4'>
                                            <div class="bg-[#FBA22D] hover:bg-[#fba22dcb] px-2 py-2 rounded-md inline-flex flex-row  items-center justify-center gap-[5px] my-3 cursor-pointer" onClick={handleClick}>
                                                <p class=" text-sm font-medium tracking-widest text-white" >Read More</p>
                                                <img src={RightArrow} />
                                            </div>

                                            <div class=" border cursor-pointer hover:border-[#FBA22D] border-[#012D4C] border-opacity-80  px-4 py-2 rounded-md inline-flex flex-row  items-center justify-center gap-[11px] my-3">
                                                <img src={SaveIcon} />

                                                <p class=" text-sm font-medium tracking-widest text-[#012D4C]">Save</p>
                                            </div>
                                        </div>


                                    </div>
                                    <div className='bg-white mt-[22px] flex flex-col rounded-lg py-[22px] px-[31px] '>
                                        <p className="text-base text-gray-800" >የአማራ ህንፃ ሥራዎች ኮንስትራክሽን ድርጅት እየገነባቸው ላሉ ፕሮጀክቶች አገልግሎት የሚውሉ የተለያዩ የቤዝመንት ውሃ ስርገት መከላከያ አቅርቦ የመግጠም ስራ ፣ ፐርፎሬትድ ፓይፕ የማቅረብ ስራ ፣ ጂኦቴክስታይል የማቅረብ ስራ ፣<br /></p>
                                        <div className="flex flex-col items-center w-full px-[31px] opacity-50 bg-opacity-80 my-[11px]"  >
                                            <div className='bg-gray-300 h-[2px] w-full' ></div>
                                        </div>
                                        <div className="inline-flex flex-col space-y-0.5 items-start justify-start w-80 h-16">
                                            <div className="inline-flex space-x-0.5 items-center justify-start w-72 h-5">
                                                <p className="text-base text-gray-800">Bid Opening Date: </p>
                                                <p className="text-sm text-gray-800">December 25, 2022</p>
                                            </div>
                                            <div className="inline-flex items-center justify-start w-5/6 h-5 space-x-1">
                                                <p className="text-base text-gray-800">Bid Closing Date: </p>
                                                <p className="text-sm text-gray-800">January 25, 2023</p>
                                            </div>
                                            <div className="inline-flex items-center justify-start w-full h-5 space-x-1">
                                                <p className="text-base text-gray-800">Published On:</p>
                                                <p className="text-sm text-gray-800">Addis Zemen - (Apr 25, 2023)</p>
                                            </div>
                                        </div>
                                        <div className='inline-flex gap-2 my-[11px]'>
                                            <div className="inline-flex space-x-2.5 items-center justify-start  h-5  ">
                                                <img src={OpenBadge} />
                                                <p className="text-base font-semibold text-green-500 " >Bid Open</p>
                                            </div>
                                            <div className="inline-flex space-x-2.5 items-center justify-start h-5">
                                                <img src={ClosedBadge} />
                                                <p className="text-base font-semibold text-red-500">Bid Closed</p>
                                            </div>

                                        </div>


                                        <div className="flex flex-col items-center w-full px-[31px] opacity-50 bg-opacity-80 my-[11px]"  >
                                            <div className='bg-gray-300 h-[2px] w-full' ></div>
                                        </div>

                                        <div className='flex items-center justify-center w-full'>
                                            <div class="   bg-opacity-50  relative overflow-hidden">
                                                <div id="leftFade" class="bg-gradient-to-r from-[#ffffff] inline to-transparent h-full w-5 absolute left-0"></div>
                                                <div id="rightFade" class="bg-gradient-to-l from-[#ffffff] inline to-transparent h-full w-5 absolute right-0"></div>
                                                <div class=" flex text-gray-600 items-center overflow-x-auto space-x-2 scroll px-3 md:flex-row flex-row  md:space-y-2 sm:space-y-2">


                                                    <div class="border cursor-pointer bg-white text-gray-600  pl-4 pr-5 min-w-max rounded font-medium py-1 flex items-center hover:border-[#FBA22D] hover:text-[#FBA22D]">
                                                        <div class="flex justify-center items-center space-x-3">
                                                            <img src={Tag} />
                                                            <div>
                                                                <span>Construction and Water Works</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="border  cursor-pointer bg-white text-gray-600  pl-4 pr-5 min-w-max rounded font-medium py-1 flex items-center hover:border-[#FBA22D] hover:text-[#FBA22D]">
                                                        <div class="flex justify-center items-center space-x-3">
                                                            <img src={Tag} />
                                                            <div>
                                                                <span>Construction and Water Works</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="border cursor-pointer  bg-white text-gray-600  pl-4 pr-5 min-w-max rounded font-medium py-1 flex items-center hover:border-[#FBA22D] hover:text-[#FBA22D]">
                                                        <div class="flex justify-center items-center space-x-3">
                                                            <img src={Tag} />
                                                            <div>
                                                                <span>Sales, Disposals and Foreclosure</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="border  cursor-pointer bg-white text-gray-600  pl-4 pr-5 min-w-max rounded font-medium py-1 flex items-center hover:border-[#FBA22D] hover:text-[#FBA22D]">
                                                        <div class="flex justify-center items-center space-x-3">
                                                            <img src={Tag} />
                                                            <div>
                                                                <span>Sales, Disposals and Foreclosure</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="border  cursor-pointer bg-white text-gray-600  pl-4 pr-5 min-w-max rounded font-medium py-1 flex items-center hover:border-[#FBA22D] hover:text-[#FBA22D]">
                                                        <div class="flex justify-center items-center space-x-3">
                                                            <img src={Tag} />
                                                            <div>
                                                                <span>Sales, Disposals and Foreclosure</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div className='flex flex-row gap-4'>
                                            <div class="bg-[#FBA22D] hover:bg-[#fba22dcb] px-2 py-2 rounded-md inline-flex flex-row  items-center justify-center gap-[5px] my-3 cursor-pointer" onClick={handleClick}>
                                                <p class=" text-sm font-medium tracking-widest text-white" >Read More</p>
                                                <img src={RightArrow} />
                                            </div>

                                            <div class=" border cursor-pointer hover:border-[#FBA22D] border-[#012D4C] border-opacity-80  px-4 py-2 rounded-md inline-flex flex-row  items-center justify-center gap-[11px] my-3">
                                                <img src={SaveIcon} />

                                                <p class=" text-sm font-medium tracking-widest text-[#012D4C]">Save</p>
                                            </div>
                                        </div>


                                    </div>
                                    <div className='bg-white mt-[22px] flex flex-col rounded-lg py-[22px] px-[31px] '>
                                        <p className="text-base text-gray-800" >የአማራ ህንፃ ሥራዎች ኮንስትራክሽን ድርጅት እየገነባቸው ላሉ ፕሮጀክቶች አገልግሎት የሚውሉ የተለያዩ የቤዝመንት ውሃ ስርገት መከላከያ አቅርቦ የመግጠም ስራ ፣ ፐርፎሬትድ ፓይፕ የማቅረብ ስራ ፣ ጂኦቴክስታይል የማቅረብ ስራ ፣<br /></p>
                                        <div className="flex flex-col items-center w-full px-[31px] opacity-50 bg-opacity-80 my-[11px]"  >
                                            <div className='bg-gray-300 h-[2px] w-full' ></div>
                                        </div>
                                        <div className="inline-flex flex-col space-y-0.5 items-start justify-start w-80 h-16">
                                            <div className="inline-flex space-x-0.5 items-center justify-start w-72 h-5">
                                                <p className="text-base text-gray-800">Bid Opening Date: </p>
                                                <p className="text-sm text-gray-800">December 25, 2022</p>
                                            </div>
                                            <div className="inline-flex items-center justify-start w-5/6 h-5 space-x-1">
                                                <p className="text-base text-gray-800">Bid Closing Date: </p>
                                                <p className="text-sm text-gray-800">January 25, 2023</p>
                                            </div>
                                            <div className="inline-flex items-center justify-start w-full h-5 space-x-1">
                                                <p className="text-base text-gray-800">Published On:</p>
                                                <p className="text-sm text-gray-800">Addis Zemen - (Apr 25, 2023)</p>
                                            </div>
                                        </div>
                                        <div className='inline-flex gap-2 my-[11px]'>
                                            <div className="inline-flex space-x-2.5 items-center justify-start  h-5  ">
                                                <img src={OpenBadge} />
                                                <p className="text-base font-semibold text-green-500 " >Bid Open</p>
                                            </div>
                                            <div className="inline-flex space-x-2.5 items-center justify-start h-5">
                                                <img src={ClosedBadge} />
                                                <p className="text-base font-semibold text-red-500">Bid Closed</p>
                                            </div>

                                        </div>


                                        <div className="flex flex-col items-center w-full px-[31px] opacity-50 bg-opacity-80 my-[11px]"  >
                                            <div className='bg-gray-300 h-[2px] w-full' ></div>
                                        </div>

                                        <div className='flex items-center justify-center w-full'>
                                            <div class="   bg-opacity-50  relative overflow-hidden">
                                                <div id="leftFade" class="bg-gradient-to-r from-[#ffffff] inline to-transparent h-full w-5 absolute left-0"></div>
                                                <div id="rightFade" class="bg-gradient-to-l from-[#ffffff] inline to-transparent h-full w-5 absolute right-0"></div>
                                                <div class=" flex text-gray-600 items-center overflow-x-auto space-x-2 scroll px-3 md:flex-row flex-row  md:space-y-2 sm:space-y-2">


                                                    <div class="border cursor-pointer bg-white text-gray-600  pl-4 pr-5 min-w-max rounded font-medium py-1 flex items-center hover:border-[#FBA22D] hover:text-[#FBA22D]">
                                                        <div class="flex justify-center items-center space-x-3">
                                                            <img src={Tag} />
                                                            <div>
                                                                <span>Construction and Water Works</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="border  cursor-pointer bg-white text-gray-600  pl-4 pr-5 min-w-max rounded font-medium py-1 flex items-center hover:border-[#FBA22D] hover:text-[#FBA22D]">
                                                        <div class="flex justify-center items-center space-x-3">
                                                            <img src={Tag} />
                                                            <div>
                                                                <span>Construction and Water Works</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="border cursor-pointer  bg-white text-gray-600  pl-4 pr-5 min-w-max rounded font-medium py-1 flex items-center hover:border-[#FBA22D] hover:text-[#FBA22D]">
                                                        <div class="flex justify-center items-center space-x-3">
                                                            <img src={Tag} />
                                                            <div>
                                                                <span>Sales, Disposals and Foreclosure</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="border  cursor-pointer bg-white text-gray-600  pl-4 pr-5 min-w-max rounded font-medium py-1 flex items-center hover:border-[#FBA22D] hover:text-[#FBA22D]">
                                                        <div class="flex justify-center items-center space-x-3">
                                                            <img src={Tag} />
                                                            <div>
                                                                <span>Sales, Disposals and Foreclosure</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="border  cursor-pointer bg-white text-gray-600  pl-4 pr-5 min-w-max rounded font-medium py-1 flex items-center hover:border-[#FBA22D] hover:text-[#FBA22D]">
                                                        <div class="flex justify-center items-center space-x-3">
                                                            <img src={Tag} />
                                                            <div>
                                                                <span>Sales, Disposals and Foreclosure</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div className='flex flex-row gap-4'>
                                            <div class="bg-[#FBA22D] hover:bg-[#fba22dcb] px-2 py-2 rounded-md inline-flex flex-row  items-center justify-center gap-[5px] my-3 cursor-pointer" onClick={handleClick}>
                                                <p class=" text-sm font-medium tracking-widest text-white" >Read More</p>
                                                <img src={RightArrow} />
                                            </div>

                                            <div class=" border cursor-pointer hover:border-[#FBA22D] border-[#012D4C] border-opacity-80  px-4 py-2 rounded-md inline-flex flex-row  items-center justify-center gap-[11px] my-3">
                                                <img src={SaveIcon} />

                                                <p class=" text-sm font-medium tracking-widest text-[#012D4C]">Save</p>
                                            </div>
                                        </div>


                                    </div>



                                    <div class="flex items-center justify-between border-t border-gray-200 bg-transparent px-4 py-3 sm:px-6">
                                        <div class="flex flex-1 justify-between sm:hidden">
                                            <a href="#" class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">Previous</a>
                                            <a href="#" class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">Next</a>
                                        </div>
                                        <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-center">
                                            <div>
                                                <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                                                    <a href="#" class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                                                        <span class="sr-only">Previous</span>
                                                        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                            <path fill-rule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clip-rule="evenodd" />
                                                        </svg>
                                                    </a>

                                                    <a href="#" aria-current="page" class="relative z-10 inline-flex items-center bg-[#FBA22D] px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">1</a>
                                                    <a href="#" class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">2</a>
                                                    <a href="#" class="relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex">3</a>
                                                    <span class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">...</span>
                                                    <a href="#" class="relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex">8</a>
                                                    <a href="#" class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">9</a>
                                                    <a href="#" class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">10</a>
                                                    <a href="#" class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                                                        <span class="sr-only">Next</span>
                                                        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                            <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                                                        </svg>
                                                    </a>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="flex justify-start bg-white lg:col-start-2 lg:col-span-2 xl:col-start-6 xl:col-span-1">
                            <div className=''>
                                <p className="text-2xl text-gray-800 ">Ads</p>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Tender