import { Route, Routes } from 'react-router-dom';
import BilihCheretaLogo from './assets/logos/bilih_chereta_logo_long.svg';
import Home from './pages/Home/Home';
import WaitingConfirmation from './pages/WaitiingConfirmation/WaitingConfirmation';


function App() {
  return (
    <>
      <Routes>
        {/* <Route
          path="/"

        >
        </Route> */}
        {/* <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<NewPassword />} />
        <Route path="/set-password" element={<SetPassword />} />
        <Route
                    path="/waiting-confirmation"
                    element={<WaitingConfirmation />}
                /> */}
        <Route
          path="/"
          element={<Home />}
        />

      </Routes>
    </>
  );
}

export default App;


