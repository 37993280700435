import React, { useState } from 'react';


function AccordionItems() {
    const [activeIndex, setActiveIndex] = useState([]);

    const handleItemClick = (index) => {
        if (activeIndex.includes(index)) {
            setActiveIndex(activeIndex.filter((item) => item !== index));
        } else {
            setActiveIndex([...activeIndex, index]);
        }
    };


    const accordionData = [
        {
            header: "What is bilih chereta?",
            text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`
        },
        {
            header: "What are the sources of tenders?",
            text: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. `
        },
        {
            header: "What payment options are available?",
            text: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature,`
        },
        {
            header: "How often is the tender database updated?f",
            text: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`
        }
    ]

    const AccordionItem = (data, key) => {


        return (
            <>
                < div class=" rounded  border border-slate-200 shadow-sm py-0" key={key} >

                    <div class="accordion-header cursor-pointer  bg-gray-100 transition flex space-x-2 items-center  px-4 py-3 items-center" onClick={() => handleItemClick(key)}>

                        <svg data-accordion-icon class="w-6 h-6  shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>

                        <p class="text-xl text-gray-800">{data.header}</p>
                    </div>

                    <div class=" px-5  border-gray-200dark:border-gray-700 w-full">
                        {activeIndex === key && <p class="leading-6 font-light text-justify py-2 ">
                            {data.text}
                        </p>}
                    </div>
                </div >
            </>
        )
    }
    return (
        <>
            <div class=" flex justify-center w-full mt-[100px]">
                <div class="rounded rounded-sm w-full px-4 md:px-[10%] lg:px-[15%] 2xl:px-[20%] flex flex-col items-center">
                    <div class=" flex flex-col items-center space-y-4">
                        <span class="text-[6vw] md:text-4xl xl:text-5xl font-bold text-[#29204e]">Frequently Asked <span class="text-[#FBA22D]">Questions?</span></span>
                    </div>

                    <div class="md:p-2 rounded mx-2 md:mx-0 my-12 xl:my-12 space-y-6 text-gray-600  w-full">

                        {accordionData.map((item, index) => (
                            <div class=" rounded  border border-slate-200 shadow-sm py-0" key={index}>

                                <div class=" cursor-pointer transition  bg-gray-100 transition flex space-x-2 items-center  px-4 py-3 w-full" onClick={() => handleItemClick(index)}>

                                    {activeIndex.includes(index) ? <svg data-accordion-icon class="w-6 h-6  shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg> : (<svg data-accordion-icon class="w-6 h-6 -rotate-90 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                    )}
                                    <p class="text-xl text-gray-800">{item.header}</p>
                                </div>



                                {/* <div class="px-5 overflow-hidden pt-0 overflow-hidden max-h-0">
                                    {activeIndex.includes(index) && (<p class="leading-6 font-light text-justify py-2 ">
                                        {item.text}
                                    </p>
                                    )}
                                </div> */}

                                <div class="px-5 overflow-hidden pt-0 overflow-hidden ">
                                    {activeIndex.includes(index) && (
                                        <p class="leading-6 font-light text-justify py-2">
                                            {item.text}
                                        </p>
                                    )}
                                </div>

                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default AccordionItems