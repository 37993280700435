import React from 'react'
import BilihCheretaHorizontalLogo from '../../assets/logos/bilih_chereta_logo_horizontal.svg';
import { FaBeer } from 'react-icons/fa';
import { ImWhatsapp } from "react-icons/im";
import { IoLogoInstagram, IoLogoFacebook } from "react-icons/io5";
import { FaTelegram, FaLinkedin } from "react-icons/fa";




function Footer() {
    return (
        <>
            <div class="w-full">
                <div class="w-full bg-gradient-to-r from-[#012D4C] via-[#012D4C] to-[#012D4C] space-y-4 pt-7 px-4 grid place-items-center text-[#FFFFFF]">

                    <div className='flex grid items-center justify-between grid-cols-1 space-x-5 space-y-5 lg:grid-cols-2'>
                        <img class="w-52" src={BilihCheretaHorizontalLogo} alt="Bilih Chereta Logo" />
                        <div class="inline-flex flex-col space-y-5 items-start justify-end w-60 h-44 ">
                            <p class="text-base text-white">Ramjet IT Solution PLC<br />Tantos Building 3rd Floor, 301<br />Addis Ababa,Ethiopia<br /></p>
                            <p class="  text-base text-white">+(251)-924-473333</p>
                            <div class="flex flex-col space-y-0.5 items-center justify-start">
                                <p class="text-base text-white">info@bilihchereta.com</p>
                                <div class="w-36 h-[0.4px] bg-white border-white" />
                            </div>
                        </div>
                    </div>


                    <div class="flex space-x-6  grid-cols-1 md:grid-cols-2  text-xs sm:text-sm md:text-xl" >
                        <p class="text-base text-white">Condition of Sale</p>
                        <p class=" text-base text-white">Cookie Policy</p>
                        <p class="text-base text-white">Privacy Policy</p>
                        <p class="text-base text-white">Terms of Use</p>
                    </div>
                    {/* <div class="flex gap-7 text-xs sm:text-sm md:text-xl text-center">
                        <span><a href="https://ichereta.com/about">About Us</a></span>
                        <span><a href="https://ichereta.com/privacy">Privacy Policy</a> </span>
                        <span><a href="https://ichereta.com/refund">Refund Policy</a></span>
                        <span><a href="https://ichereta.com/TOS">Terms of service</a></span>
                    </div> */}

                    <div class="flex gap-4 md:gap-6  ">


                        <a><ImWhatsapp className='w-8 h-8' /></a>
                        <a><IoLogoInstagram className='w-8 h-8' /></a>
                        <a><IoLogoFacebook className='w-8 h-8' /></a>
                        <a><FaTelegram className='w-8 h-8' /></a>
                        <a><FaLinkedin className='w-8 h-8' /></a>

                    </div>

                    <div className='pb-5'>
                        <p class="opacity-80 text-sm text-white ">© 2023 Ramjet IT Solutions. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer


{/* <nav id="navContainer" class="border-gray-200 text-[#664bc7] md:text-sm lg:text-xl px-2 md:px-0 py-2 ">
                <div class="flex flex-wrap flex-row-reverse items-center justify-start py-2 md:mx-[5%] xl:mx-[7%] relative">
                    <a href="/" class="absolute left-0 top-2">
                        <img class="w-24 sm:w-32 lg:w-40" src="/assets/images/logowitht.svg" alt="" />
                    </a>
                    <div class="flex items-center">

                        <Button id="burggerMenu" data-collapse-toggle="mobile-menu" type="button" class="md:hidden ml-3 text-gray-400 hover:text-gray-900 focus:outline-none  rounded-lg inline-flex items-center justify-center" aria-controls="mobile-menu-2" aria-expanded="false">
                            <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 18 2H4a1 1 0 01-1-1zM3 8a1 1 0 011-1h12a1 1 0 18 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 18 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path>
                            </svg>
                            <svg class="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L8 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 8l4.293 4.293a1 1 0 01-1.414 1.414L8 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 8 4.293 5.707a1 1 0 08-1.414z" clip-rule="evenodd"></path>
                            </svg>
                        </Button>
                        <Button></Button>
                    </div>
                    <div class="hidden md:block w-full md:w-auto" id="mobile-menu">
                        <ul class="flex-col md:flex-row flex md:space-x-6 mt-4 md:mt-0 font-light text-[#664bc7] divide-y divide-slate-80 md:divide-transparent">
                            <li class="flex items-center">
                                <div onclick="searchArea()" class="flex  flex-col cursor-pointer hidden md:block lg:hidden items-center  rounded-t-lg ">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-5 ">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 85.196 5.196a7.5 7.5 0 008.607 8.607z"></path>
                                    </svg>
                                </div>
                            </li>
                            <li class="flex items-center">
                                <a href="https://ichereta.com/tenders" class="hover:bg-gray-50  flex items-center md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0" aria-current="page">Tenders </a>
                            </li>
                            <li class="flex items-center">
                                <a href="https://ichereta.com/pricing" class="hover:bg-gray-50 border-b flex items-center  border-gray-80 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0">Pricing </a>
                            </li>
                            <li class="flex items-center">
                                <a href="https://ichereta.com/FAQ" class="hover:bg-gray-50 border-b flex items-center  border-gray-80 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0">FAQ </a>
                            </li>
                            <li class="flex items-center">
                                <a href="https://ichereta.com/register" class="hover:bg-gray-50 border-b flex items-center  border-gray-80 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0">Sign Up </a>
                            </li>
                            <li class="flex items-center">
                                <a href="https://ichereta.com/login" class="border-b border-gray-80 flex items-center bg-[#664bc7] bg-opacity-30 font-bold rounded md:border-0 block pl-3 px-4 py-2 ">Login </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav> */}